<template>
  <div>
    <vue-apex-charts
      type="line"
      height="350"
      :options="chartOptions"
      :series="report.series"
    />
  </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts'

export default {
  name: 'ReportChart',
  components: {
    VueApexCharts,
  },
  data() {
    return {
      series: [{
        name: 'Türkiye Sıralaması',
        type: 'column',
        data: [35, 1, 28, 24, 28, 20, 25],
      }, {
        name: 'Hedef',
        type: 'area',
        data: [94, 94, 94, 94, 94, 94, 94],
      }, {
        name: 'Skor',
        type: 'line',
        data: [94.3, 99.6, 96.7, 96.5, 95.1, 97.2, 95.3],
      }],
      chartOptions: {
        chart: {
          height: 350,
          type: 'line',
          stacked: false,
        },
        stroke: {
          width: [0, 2, 5],
          curve: 'smooth',
        },
        plotOptions: {
          bar: {
            columnWidth: '50%',
          },
        },

        fill: {
          opacity: [0.85, 0.25, 1],
          gradient: {
            inverseColors: false,
            shade: 'light',
            type: 'vertical',
            opacityFrom: 0.85,
            opacityTo: 0.55,
            stops: [0, 100, 100, 100],
          },
        },
        labels: ['OCAK', 'ŞUBAT', 'MART', 'NİSAN', 'MAYIS', 'HAZİRAN', 'TEMMUZ',
          'AĞUSTOS', 'EYLÜL', 'EKİM', 'KASIM', 'ARALIK'],
        markers: {
          size: 0,
        },
        xaxis: {
          //  type: 'datetime',
        },
        yaxis: {
          title: {
            text: 'CSI',
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter(y) {
              if (typeof y !== 'undefined') {
                return y
              }
              return y
            },
          },
        },
      },
    }
  },
  computed: {
    report() {
      return this.$store.getters['csiBrandsReport/getReport']
    },
  },
}
</script>
